import React from "react";

import LazyHydrate from "@components/utils/LazyHydrate";

import * as Styled from "./styles";
import { CenteredIconBoxProps } from "./types";
import CenteredIconBoxWrapper from "./partials/CenteredIconBoxWrapper";

const CenteredIconBox: React.FC<CenteredIconBoxProps> = ({
  icon,
  title,
  href
}) =>
  icon ? (
    <div>
      <CenteredIconBoxWrapper href={href}>
        <LazyHydrate whenVisible>
          <Styled.Icon src={icon} />
        </LazyHydrate>
        {title && <Styled.Title>{title}</Styled.Title>}
      </CenteredIconBoxWrapper>
    </div>
  ) : null;

export default CenteredIconBox;
