import React from "react";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import { CenteredIconBoxWrapperProps } from "./types";
import { StyledLink } from "./styles";

const CenteredIconBoxWrapper: React.FC<
  CenteredIconBoxWrapperProps
> = ({ href, children }) =>
  href ? (
    <StyledLink href={href}>{children}</StyledLink>
  ) : (
    <FlexBox flexDirection="column" alignItems="center">
      {children}
    </FlexBox>
  );

export default CenteredIconBoxWrapper;
