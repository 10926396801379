import styled from "styled-components";

import Link from "@components/atoms/Link";

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ${({ theme }) => theme.transition};

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
    fill: ${({ theme }) => theme.colors.brand};
  }
`;
