import React from "react";
import { storyblokEditable } from "@storyblok/react";

import LazyHydrate from "@components/utils/LazyHydrate";
import CenteredIconBox from "@components/molecules/CenteredIconBox";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";

import { CenteredIconBoxStoryblokProps } from "./types";

const CenteredIconBoxStoryblok: React.FC<
  CenteredIconBoxStoryblokProps
> = ({ blok }) => {
  const { icon, title, href } = blok;

  const {
    cached_url: cachedUrl,
    story: { full_slug: fullSlug = "" } = {}
  } = href ?? {};

  const item = {
    icon: tryToReplaceDomain(icon.filename),
    title,
    href: fullSlug || cachedUrl || ""
  };

  return (
    <LazyHydrate
      useDisplayContents={false}
      noWrapper
      whenVisible
      getEventTarget={() => window}
    >
      <CenteredIconBox {...{ ...item, ...storyblokEditable(blok) }} />
    </LazyHydrate>
  );
};

export default CenteredIconBoxStoryblok;
